/*--- dashboard css --*/
/* @font-face {
    font-family: "Helvetica";
    src: url("/src/fonts/Helvetica-Bold.ttf") format("ttf"),
        url("/src/fonts/Helvetica.ttf") format("truetype");
} */

@font-face {
  font-family: "Bangers-Regular";
  src: url("../fonts/Bangers-Regular.ttf") format("truetype");
}

/* @font-face {
    font-family: 'ComicNeue-Regular';
    src: url("../fonts/ComicNeue-Regular.ttf")format('truetype'),
} */
@font-face {
  font-family: "ComicNeue-Regular";
  src: url("../fonts/ComicNeue-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ComicNeue-Bold";
  src: url("../fonts/ComicNeue-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "ComicNeue-Light";
  src: url("../fonts/ComicNeue-Light.ttf") format("truetype");
}

:root {
  --white: #ffffff;
  --black: #000000;
  --midium_black: #323232;
  --light_grey: #c3c3c3;
  --title-grey: #d9d9d9;
  --subtitle-grey: #838383;
  --mahroon: #6a3851;
  --yellow: #fecb00;
  --purple: #3f0866;
  --font_banger: Bangers-Regular;
  --font_comicRegular: ComicNeue-Regular;
  --light-brown: #f0f0f0;
  --light-pink-background: #7640cc1a;
  /* --font_comicLight: ComicNeue-Light;
    --font_comicBold: ComicNeue-Bold; */
}

body {
  /* overflow: hidden; */
  background-color: #f0f0f0;
}

.dark-purple-color {
  color: var(--purple) !important;
}

.medium-black-color {
  color: #323232 !important;
}

/* global font-size and color  */
.content p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
b,
strong,
body {
  /* font-family: Helvetica; */
  font-family: var(--font_comicRegular);
}

p {
  color: #323232;
  font-size: 24px !important;
  line-height: 30px !important;
  font-family: var(--font_comicRegular);
  font-weight: 700;
}

p a {
  color: var(--purple);
}

a:hover,
p a:hover {
  color: var(--mahroon);
}

a {
  font-family: var(--font_comicRegular);
  color: var(--purple);
}

.bold-font {
  font-weight: 600 !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

#card1 {
  font: normal normal normal 17px/21px var(--font_comicRegular);
}

.comic-font {
  font-family: var(--font_comicRegular);
}

.col-md-10.content-widthsidebar.content-wrapper {
  overflow: scroll;
  height: 100vh;
}

.content-widthsidebar {
  margin-left: 250px !important;
  padding: 14px;
  max-width: calc(100% - 250px);
  background: #f0f0f0;
}

.dashboard-footer {
  position: fixed;
  bottom: 0px;
  min-height: 90px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -7px 26px #0000001a;
  width: auto;
  left: 250px;
  padding: 0px 30px;
  right: 0px;
  height: 113px;
}

.dashboard-footer > div {
  width: 100%;
}

.dashboard-footer > div > .row {
  align-items: center;
  justify-content: space-between;
}

body:not(.layout-fixed) .main-sidebar {
  height: 100vh;
  background-color: var(--purple);
}

/* .dashboardContent>.row {
    margin: 0px;
} */
.css-14el2xx-placeholder {
  font-family: var(--font_comicRegular);
}

.css-1f9cgau-control {
  font-family: var(--font_comicRegular);
}

.css-b62m3t-container {
  font-family: var(--font_comicRegular);
}

.ftboxlinks-blue > a {
  background-color: #0f4beb;
  color: #fff;
  padding: 8px;
  text-align: center;
  border-radius: 3px;
  font-size: 15px;
  display: block;
}

.ftboxlinks-red > a {
  /* background-color: #FF2305; */
  background-color: #8b0000;
  color: #fff;
  padding: 8px;
  font-size: 13px;
  text-align: center;
  border-radius: 3px;
  display: block;
}

.ftboxlinks-blue > a:hover {
  opacity: 0.8;
}

.questions-table tbody tr {
  display: flex;
  flex-wrap: wrap;
}

/* .questions-table tbody tr td:first-child {
    width: 100%;
    font: normal normal bold 18px/24px va;
    letter-spacing: 0px;
    color: #323232;
} */
.questions-table tbody tr td:first-child {
  width: 100%;
  letter-spacing: 0px;
  color: #323232;
  font-size: 24px !important;
  line-height: 30px !important;
  font-family: var(--font_comicRegular);
  font-weight: 700;
}

table.table.questions-table,
.content-topheads-text {
  background: #fff;
}

.questions-table tbody tr td {
  display: flex;
  width: 20%;
}

.questions-table tbody tr .avoidence {
  width: 16%;
}

@media (max-width: 992px) {
  .questions-table tbody tr .avoidence {
    width: 100%;
  }

  .questions-table tbody tr td {
    display: flex;
    width: 100%;
  }
}

/* .questions-table tbody tr td label {
    display: inline-block;
    font: normal normal 16px/24px Helvetica;
    letter-spacing: 0px;
    color: #323232;
    font-weight: normal !important;
    margin-left: 5px;
} */

.questions-table tbody tr td label {
  display: inline-block;
  margin-left: 5px;
  width: 100%;
  letter-spacing: 0px;
  color: #323232;
  font-family: var(--font_comicRegular);
  font-size: 20px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 32px;
}

.questions-table tbody tr td span {
  border: none;
  background: none;
  padding: 0px;
  display: inline-block;
  padding-top: 5px;
}

.questions-table tbody tr td span input {
  margin: 0px;
  position: relative;
}

.content-topheads-text {
  /* padding: 0.75rem; */
  margin-bottom: 15px;
}

.row.header-titlewith-text {
  /* padding: 0.95rem 0px; */
  /* margin-bottom: 15px; */
  padding-bottom: 20px;
}

.row.header-titlewith-text .title {
  font: normal normal bold 28px/34px Helvetica;
  letter-spacing: 0px;
  color: #212529;
}

.row.header-titlewith-text .text-right {
  font: normal normal normal 15px/28px var(--font_comicRegular);
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
  align-self: center;
}

.row.header-titlewith-text .text-right span {
  /* color: #0F4BEB; */
  color: var(--purple);
}

.content-with-sidebar-row .content-wrapper {
  width: calc(100% - 255px);
  max-width: calc(100% - 255px);
  padding-bottom: 160px;
}

.note-textheads {
  font: normal normal bold 17px/21px Helvetica;
  letter-spacing: 0px;
  color: #000000;
  border-bottom: solid 1px #eee;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.ft-headstext,
.audio-title {
  font: normal normal normal 16px/21px var(--font_comicRegular);
  letter-spacing: 0px;
  color: #323232;
  margin-bottom: 8px;
  font-weight: 700;
}

.audio-player,
.audio-player audio {
  /* height: 30px; */
  display: flex;
  justify-content: center;
}

.subtext-blocksec span {
  font: normal normal normal 13px/21px var(--font_comicRegular);
  letter-spacing: 0px;
  color: #323232;
  margin-bottom: 5px;
  display: inline-block;
  font-weight: 700;
}

.fticons {
  width: 16px;
}

.ftboxlinks-red a:hover {
  opacity: 0.8;
}

/* placeholder font size */
::placeholder {
  font-size: 14px;
}

.form-group label {
  font-weight: normal !important;
}

.form-control {
  background: #ecf1fe;
}

.css-1s2u09g-control {
  background: #ecf1fe !important;
}

.hover-txt {
  bottom: 70px;
  width: 400px;
  background-color: #212529;
  color: #fff;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 9999999999 !important;
  right: 0px;
  font-size: 14px;
}

.signuptext a {
  font-size: 17px;
}

.content-div {
  overflow: scroll;
  height: 100vh;
  width: 100%;
  max-width: 100%;
}

.user-reponse-submit {
  background: var(--yellow);
  color: var(--mahroon);
  font-weight: bold;
  width: fit-content;
  margin-left: 8px;
  font-size: 15px;
  border-radius: 4px;
  padding: 4px 10px;
  font-family: var(--font_comicRegular);
  border: none !important;
}

.user-reponse-submit:hover {
  color: var(--mahroon);
}

/* .user-reponse-submit:hover{
    opacity: .6;
} */

.user-reponse-submit1 {
  background: var(--yellow);
  color: var(--mahroon);
  font-weight: bold;
  width: fit-content;
  margin-left: 8px;
  font-size: 15px;
  border-radius: 4px;
  padding: 4px 10px;
  font-family: var(--font_comicRegular);
  border: none !important;
}

.user-reponse-submit1:hover {
  opacity: 0.6;
  color: var(--mahroon);
}

button.user-reponse-submit.btn.btn-primary.link-sty.btn.btn-primary {
  background: var(--yellow);
  color: var(--purple);
  font-weight: bold;
  width: fit-content;
  margin-left: 0px;
  font-size: 20px;
  border-radius: 10px;
  padding: 4px 10px;
  border: none;
  font-family: var(--font_comicRegular);
  padding: 20px 30px;
  /* margin-left: 9px; */
}

@media (max-width: 500px) {
  button.user-reponse-submit.btn.btn-primary.link-sty.btn.btn-primary {
    margin-left: 0px;
    font-size: 20px;
    border-radius: 5px;
    /* padding: 4px 10px; */
    border: none;
    font-family: var(--font_comicRegular);
    padding: 20px 30px;
    /* margin-left: 9px; */
  }
}

Button .downloadButton {
  /* background: #FF5733; */
  background: #8b0000;
  color: #fff;
  font-weight: bold;
  width: fit-content;
  margin-left: 0px;
  font-size: 15px;
  border-radius: 4px;
  padding: 10px 10px;
  border: #ff5733;
  /* border: '#8b0000' */
}

.modal-no-button {
  background: var(--light_grey);
  color: var(--purple);
  font-weight: bold;
  width: fit-content;
  margin-left: 0px;
  font-size: 20px;
  border-radius: 10px;
  padding: 4px 10px;
  border: none;
  font-family: var(--font_comicRegular);
  padding: 20px 30px;
}

.modal-no-button:hover {
  background: var(--light_grey);
  color: var(--purple);
}

/* radio button css  */
input[type="radio"]:after {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ebebeb;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid #b2b2b2;
}

input[type="radio"]:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: "";
  visibility: visible;
  /* border: 5px solid #009b00; */
  border: 5px solid var(--mahroon);
}

[class*="sidebar-dark"] .user-panel {
  border-bottom: 1px solid #b2b2b2 !important;
}

.user-panel.navigation-link {
  margin-top: 15px !important;
}

.user-panel.navigation-link .info {
  width: 100%;
}

.web-logo .panelLogo-container {
  padding: 1px !important;
  border-radius: 5px;
}

.web-logo .panelLogo-container img {
  height: 33px;
  width: 69px;
  box-shadow: 0px 0px 3px #ccc !important;
  border-radius: 5px;
}

.web-logo .panel-logo-name {
  font-size: 20px !important;
  margin-bottom: 0px;
  margin-top: 4px;
  margin-left: 8px;
}

/* end */
.col-md-7.ftboxlinks-blue,
.ftboxlinks-red a {
  font: normal normal bold 15px/28px Helvetica;
}

.questions-table tbody tr td label {
  margin-left: 8px;
}

/* loading icon css */
.loader-icon svg {
  height: 70vh;
  position: relative;
  bottom: 50% !important;
  left: 50%;
}

.col-md-7.ftboxlinks-blue,
.ftboxlinks-red a {
  font: normal normal bold 15px/28px Helvetica;
}

.content div {
  margin: 14px 0px;
  align-items: end;
}

.card-body .title {
  font: normal normal 700 16px/20px var(--font_comicRegular);
  letter-spacing: 0px;
  color: var(--black);
  padding: 15px 0px 15px 17px;
  background-color: var(--light-pink-background);
  /* border-radius: 4px 4px 0px 0px; */
  font-weight: 400;
}

.card-body-main-container {
  padding: 0px 44px 44px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome-content .card-body .link-sty a {
  background: #0f4beb;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  border-radius: 4px;
  padding: 4px 10px;
}

.link-sty a {
  background: #0f4beb;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 10px;
}

.welcome-content .card-body div {
  /* margin-top: 10px; */
}

.imp-advice-sty li {
  list-style: none;
  margin-bottom: 2px;
}

.imp-advice-sty li a {
  color: var(--purple);
}

.imp-advice-sty li a:hover {
  color: var(--mahroon);
}

.imp-advice-sty li a svg.ext {
  height: 15px;
  width: 15px;
  fill: var(--mahroon) !important;
  margin-left: 3px;
  vertical-align: baseline;
}

.sub-list {
  list-style: none;
}

div#toolTip {
  z-index: 9999;
}

.questions-table span.input-group-text {
  margin-left: 6px;
}

.questions-table thead tr th {
  font: normal normal bold 17px/21px Helvetica;
  letter-spacing: 0px;
  color: #212529;
}

.content-widthsidebar.content-wrapper .card-body {
  margin-bottom: 7px;
  padding: 16px;
}

.questions-table td,
.table th {
  border: none !important;
}

/* red heading text */
.redText {
  color: #8b0000;
  font-weight: bold;
  font-family: Helvetica;
}

.redText1 {
  color: #8b0000;
  font: normal normal normal 22px/24px Helvetica;
}

.TextsLast {
  color: #323232;
  font: normal normal normal 16px Helvetica;
  padding-top: 4px;
  line-height: 30px !important;
}

.paragraText {
  color: #8b0000;
  font-size: 24px !important;
  font-family: Helvetica;
  font-weight: bold;
}

.ptext {
  margin-top: 50px;
}

.paragraText {
  color: #8b0000;
  font-size: 24px !important;
  font-family: Helvetica;
  font-weight: bold;
}

.ptext {
  margin-top: 50px;
}

.info img {
  object-fit: cover;
  height: 33px;
  width: 134px;
}

.img-how-to-sty {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 10%);
  padding: 20px;
  text-align: center;
}

.col-md-10.content-widthsidebar.content-wrapper .card {
  margin-bottom: 10rem;
}

.col-md-10.content-widthsidebar.content-wrapper .card1 {
  margin-bottom: 3rem;
}

.img-how-to-sty {
  text-align: center;
}

.img-how-to-sty img {
  width: 90%;
}

.content .wait-for-the .row {
  align-items: center !important;
}

.question-image img {
  border-radius: 4px;
}

table.thinking_table tr.questions {
  /* background-color: #ff2305; */
  /* background-color: #8b0000; */
  background-color: var(--mahroon);
  font-weight: bold;
  color: #ffffff;
  font-family: var(--font_comicRegular);
}

table.thinking_table tr.answers,
table.thinking_table td p {
  color: #323232;
  /* color: '#fff'; */
  font-family: var(--font_comicRegular);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* font-size: 20px !important; */
}

table.thinking_table tr.answers {
  /* background-image: url("../assets//GIf/127.png"); */
  background-color: var(--yellow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: conver;
  /* color: #fff; */
}

.thinking_table {
  padding: 10px;
}

.thinking_table td {
  padding: 15px;
  word-break: break-word;
  font-size: 24px;
}

.casualThought-table td {
  border: 1px solid #dfdfdf;
}

.casualThought-table tr:nth-child(2n + 1) {
  background-color: var(--light-pink-background);
}

.casualThought-table .headings {
  background-color: var(--mahroon) !important;
}

.casualThought-table .headings td p {
  font-weight: bold;
  color: #ffffff;
}

.thoughts {
  margin-bottom: 15px;
}

table.challenge_table tr.questions {
  /* background-color: #ff2305; */
  background-color: #8b0000;
  font-weight: bold;
  color: #ffffff;
}

.part-two-challenge table.challenge_table {
  margin-bottom: 30px;
  width: 100%;
}

marginBotoom {
  margin-bottom: 10rem !important;
}

.alignLeft {
  display: flex;
  align-items: flex-start !important;
}

table.challenge_table:hover {
  box-shadow: 0px 0px 20px 0px #ccc;
}

table.challenge_table {
  border-radius: 5px;
  box-shadow: 4px 4px 20px 8px #ccc;
}

table.thinking_table {
  border-radius: 5px;
  width: 90%;
  box-shadow: 4px 4px 20px 8px #ccc;
}

p.selected-txt-sty {
  font-weight: bold;
  border-radius: 4px;
  background: #ecf1fe0f;
  margin-top: 15px;
}

.question-image img {
  width: auto;
  height: 327px;
  object-fit: cover;
}

.left-content row {
  align-items: end;
}

.left-content .ipForms {
  margin-bottom: 40px !important;
}

.thinkingEx .row {
  align-items: start !important;
}

.thinkingEx .sampleAnswers {
  margin-bottom: 40px;
}

/* .thinkingEx .sampleAnswers .component-sub-heading-style {
    margin: 24px !important;
} */

.dropdown-wrapper {
  position: relative;
  width: 200px;
}

.dropdown-wrapper .dropdown {
  background: #fff;
  width: 200px;
  height: 100px;
  top: 50px;
  left: 0;
  font-family: "Open Sans";
  font-size: 22px;
  display: flex;
  align-items: center;
}

.ul-wrap ul {
  background: #fff;
  color: #000000;
  list-style: none;
  margin: 0;
  font-size: 18px;
  overflow: hidden;
  height: 0px;
  transition: height 0.3s ease;
  padding: 0;
}

.ul-wrap ul.active {
  height: auto;
  overflow: auto;
}

/* sidebar css  */
h2.accordion-header {
  font-size: 16px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  /* background-color: #343a40 !important; */
  background-color: var(--purple);
  color: #fff !important;
}

.sidebar-menu-item.active div#panel1a-header {
  /* background: #007bff; */
  background: #fff;
  padding: 0px 10px;
  align-items: center;
  border-radius: 4px;
  height: 64px;
  margin-bottom: 8px;
  color: red !important;
}

.sidebar-menu-item.active div#panel1a-header span.headText .link-enable {
  color: var(--purple) !important;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 30px !important;
  height: 30px !important;
}

div#panel1a-header {
  padding: 0px 10px;
  align-items: center;
  border-radius: 4px;
  height: 64px;
  margin-bottom: 8px;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: inherit;
  box-shadow: none !important;
  padding: 0px 16px 8px 16px !important;
}

p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root {
  color: #fff !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: unset !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}

span.headText {
  margin: 0px;
  color: #fff !important;
  height: auto;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  fill: #fff;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  transform: rotate(90deg);
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  transform: rotate(270deg);
}

.css-b62m3t-container {
  width: 100%;
}

/* .info svg {
  fill: #fff;
} */

.sidebar-menu-item.active
  div#panel1a-header
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  fill: var(--purple) !important;
  font-weight: 700 !important;
}

.sidebar-menu-item.active
  div#panel1a-header
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
  fill: var(--purple) !important;
  font-weight: 700 !important;
}

span.headText {
  white-space: normal;
}

div#panel1a-content p {
  white-space: break-spaces;
  margin-bottom: 8px;
}

.info .MuiPaper-root {
  color: #fff;
  box-shadow: none;
  background-color: transparent;
}

.info .MuiAccordionSummary-expandIconWrapper svg {
  transform: rotate(90deg);
  fill: #fff;
}

.info .Mui-expanded svg {
  transform: rotate(180deg);
}

.info span.item-menu-list.active.previous {
  background: #ffffffbd;
  padding: 5px 8px;
  height: 55px;
  color: #1b1818;
  border-radius: 5px;
  display: flex;
  white-space: break-spaces;
  align-items: center;
  margin-bottom: 2px;
}

.info span.item-menu-list.active {
  background: #fff;
  padding: 5px 8px;
  height: 55px;
  color: #1b1818;
  border-radius: 5px;
  display: flex;
  white-space: break-spaces;
  align-items: center;
  margin-bottom: 2px;
}

.questions div {
  margin: 0px;
}

p.questions {
  margin: 0px;
}

ul li {
  margin-bottom: 5px;
}

.user-res-situation {
  font-size: x-large;
  /* margin: 20px 0; */
  text-align: center;
  padding-bottom: 16px;
}

.usr-jrny-text {
  text-align: center;
  color: #8b0000;
  font-size: 31px;
}

.textbox textarea {
  background: transparent;
  min-height: 94px;
  /* background-color: var(--yellow) !important; */
}

div#panel1a-content p {
  color: #fff;
}

.info {
  padding: 0px !important;
}

.sidebar::-webkit-scrollbar {
  display: none !important;
}

.sidebar:hover ::-webkit-scrollbar {
  display: none !important;
}

.sidebar {
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sidebar .web-logo {
  border-bottom: 1px solid #b2b2b2 !important;
  align-items: center;
  padding-left: 10px;
  padding: 15px 0px 15px 10px;
}

.sidebar .web-logo .info {
  background: #fff;
  /* padding: 10px 0px !important; */
  border-radius: 5px;
  width: 69px;
  margin-right: 10px;
  height: 33px;
}

.sidebar .web-logo .info img {
  width: 100%;
}

.sidebar .web-logo .logo-name {
  color: #fff;
  text-align: center;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 30px;
}

.user-panel.d-flex.name {
  padding: 10px 10px;
}

/* table.table.more-safety-behaviours tr td img {
  width: 100px;
  height: auto;
  object-fit: cover;
} */

/* table.table.more-safety-behaviours tr.headings {
  background: #8b0000;
  color: #fff;
} */

table.table.more-safety-behaviours tr td img {
  width: 100px;
  height: auto;
  object-fit: cover;
}

table.table.more-safety-behaviours tr.headings {
  background: var(--mahroon);
  color: #fff;
  font-family: var(--font_comicRegular);
  font-size: 24px;
}

table.table.more-safety-behaviours tr.headings td {
  color: #fff;
  font-family: var(--font_comicRegular);
  font-size: 24px;
  font-weight: 700;
}

div#edit-feared-situation-text {
  background-color: #e3f4fb;
  border: 1px dashed #29aae2;
  border-bottom: 0;
  padding: 10px;
  box-sizing: border-box;
  margin: 0;
  border-bottom: 1px dashed #29aae2;
}

div#edit-feared-situation {
  background-color: #e3f4fb;
  border: 1px dashed #29aae2;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

div#edit-feared-situation div {
  margin-bottom: 5px;
}

div#edit-feared-situation div input[type="radio"] {
  margin-right: 3px;
}

div#edit-reason-for-avoidance-text {
  margin: 20px 0 0;
  background-color: #ebe4f1;
  border: 1px dashed #673092;
  border-bottom: 0;
  padding: 10px;
}

div#edit-reason-for-avoidance {
  background-color: #ebe4f1;
  border: 1px dashed #673092;
  padding: 10px;
}

div#edit-reason-for-avoidance div {
  margin-bottom: 5px;
}

div#edit-reason-for-avoidance div input[type="radio"] {
  margin-right: 3px;
}

/* css for last page  */
.firstContent {
  background-color: #e3f4fb;
  border: 1px dashed #29aae2;
  width: 40% !important;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: left;
}

.firstContent h1 {
  font-size: 32px;
  color: #29aae2;
  padding: 10px;
  text-align: center;
  border-bottom: 1px dashed #29aae2;
  font-weight: bold;
}

.firstContent p {
  padding: 10px;
}

.secondContent {
  margin-top: 20px;
  background-color: #ebe4f1;
  border: 1px dashed #673092;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: left;
  width: 40% !important;
}

.secondContent h1 {
  font-size: 32px;
  color: #673092;
  padding: 10px;
  text-align: center;
  border-bottom: 1px dashed #673092;
  font-weight: bold;
}

.secondContent p {
  padding: 10px;
}

.thirdContent {
  margin-top: 20px;
  background-color: #ebe4f1;
  border: 1px dashed #673092;
  width: 40% !important;
  border-radius: 5px;
  box-sizing: border-box;
}

.thirdContent h1 {
  font-size: 32px;
  color: #673092;
  padding: 10px;
  margin: 0px;
  font-weight: bold;
}

.rowContent {
  display: flex;
  margin-top: 30px;
  position: relative;
}

.rowContent div {
  height: fit-content;
}

.mainContent {
  margin: 0px 2%;
  text-align: -webkit-center;
}

.firstCol,
.thirdCol {
  background: #ebe4f1;
  border: 1px dashed #673092;
  border-radius: 5px;
  text-align: left;
  margin: 0px 80px;
}

.firstCol h4,
.thirdCol h4 {
  color: #673092;
  padding: 10px;
  text-align: center;
  border-bottom: 1px dashed #673092;
  font-weight: bold;
}

.secondCol {
  background-color: #e3f4fb;
  border: 1px dashed #29aae2;
  border-radius: 5px;
  text-align: left;
}

.secondCol h4 {
  color: #29aae2;
  padding: 10px;
  text-align: center;
  border-bottom: 1px dashed #29aae2;
  font-weight: bold;
}

.thirdCol ul,
.firstCol ul,
.secondCol ul {
  padding: 36px;
}

.checkbx-sty.left-content .input-group-text {
  display: inline-block;
  align-items: center;
  padding: 11px 0px;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: transparent;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: none;
  border-radius: 0.25rem;
}

.left-content.checkbx-sty {
  margin-left: 4.5%;
  font-family: var(--font_comicRegular);
  font-size: 20px;
}

button.loadmoreBtn.btn.btn-primary {
  border: none;
  background: transparent;
  text-decoration: underline;
  /* color: #0f4beB; */
  color: var(--purple);
}

.right-img-sty {
  top: 6%;
  position: absolute;
  right: 27%;
}

.right-img-sty-2 {
  top: 26%;
  position: absolute;
  right: 27%;
}

.left-img-sty {
  position: absolute;
  top: 10%;
  left: 23%;
}

.left-img-sty2 {
  top: 42%;
  position: absolute;
  left: 26%;
}

.right-img-sty3 {
  bottom: 51%;
  position: absolute;
  left: 71%;
}

span.mid-img-sty2 {
  position: absolute;
  left: 34%;
  bottom: 92%;
}

span.mid-img-sty {
  bottom: 92%;
  position: absolute;
  right: 34%;
}

span.right-inter img {
  position: relative;
  top: 50%;
  right: 88%;
}

span.left-inter img {
  position: relative;
  left: 88%;
  top: 50%;
}

ul.safety-b-list {
  list-style: none;
  margin-bottom: 5px;
}

ul.safety-b-list li {
  margin-bottom: 7px;
}

table.table.more-safety-behaviours td,
table.table.more-safety-behaviours th {
  border: 1px solid var(--purple);
  margin-bottom: 5px;
  color: #323232;
  font-family: var(--font_comicRegular);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.table-res-sty {
  overflow-y: scroll;
}

table.table.more-safety-behaviours {
  margin-bottom: 20px;
  /* width: 700px; */
}

p.firstSec {
  color: #29aae2;
  font-weight: bold;
  margin: 0;
  font-size: 30px !important;
}

p.newSec {
  color: #0f4beb;
  font-weight: bold;
  margin: 0;
}

p.secondSec {
  color: #673092;
  font-weight: bold;
  margin: 0;
  font-size: 30px !important;
}

.col-md-10.content-wrapper.content-div.btn-show {
  padding-bottom: 165px;
}

table.table.questions-table.predictions td.options {
  width: 9%;
}

.radio-button span.input-group-text {
  border: none;
  margin-bottom: 5px;
  margin-left: 7px;
  background: none;
}

.radio-item-wrap p {
  margin: 0;
}

.radio-item-wrap {
  margin: 10px 0px;
  display: flex;
}

.col-md-8.loader-icon.loader-icon svg {
  height: auto;
}

.experimentHead {
  font-size: x-large !important;
  font-weight: 700;
}

.content-topheads-text .user-res-situation,
.res-user-st {
  font-weight: 700;
  text-align: center;
}

h2.attText a {
  font-size: 24px;
  font-weight: 700;
  color: var(--purple);
  text-decoration: underline;
}

a.atlink {
  color: var(--purple);
  text-decoration: underline;
}

h2.attText a:hover,
a.atlink:hover {
  color: var(--mahroon);
}

h3.questions.sarRes {
  font-weight: 700;
  margin-top: 40px;
}

h2.attText {
  text-align: center;
}

h3.questions.sarRes {
  font-weight: 700;
  text-align: center;
  margin-top: 40px;
}

span.blueText {
  color: var(--purple);
}

.adminPanel {
  padding-bottom: 90px !important;
}

.asd {
  /* border: 1px solid #000001; */
  background: var(--yellow);
  color: var(--mahroon);
  border-radius: 5px;
  font-weight: 700;
  padding: 5px;
  cursor: pointer;
  padding: 5px 15px;
}

.row.toprow h2 {
  font-weight: bold;
  font-size: 24px !important;
  color: #212529;
}

select.dropDown {
  font-size: 13px;
  font-family: var(--font_comicRegular);
}

.css-3iigni-container {
  width: 100%;
}

.save-draft-btn {
  float: right;
  margin-right: 8px;
}

.draftButton {
  float: right;
  margin-right: 20px;
}

a.link-enable:hover {
  text-decoration: underline !important;
}

/* cusror pointer not allowed in sidebar */
.link-disable-wrap {
  cursor: not-allowed;
}

/* disable links */
a.link-disable {
  pointer-events: none;
}

span.item-menu-list a {
  font-size: 14px;
}

.col-lg-6.region.region-content {
  /* height: 80vh !important; */
  height: auto;
  text-align: center;
  padding-top: 5%;
  box-shadow: 5px 7px 20px 0px #cccc;
  margin: 4% 0px;
  background: #ffffff54;
  border-radius: 8px;
}

.col-lg-6.region.region-content.welcome {
  /* height: 80vh !important; */
  height: auto;
  text-align: center;
  padding: 2% 0px;
  box-shadow: 5px 7px 20px 0px #cccc;
  margin: 4% 0px;
  background: #ffffff54;
  border-radius: 8px;
}

.dashboardContent
  .error-template
  nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  margin: 0 !important;
  position: relative;
}

nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  margin: 0 !important;
}

/*  Responsive css  */
@media screen and (min-device-width: 1440px) and (max-device-width: 1652px) {
  .left-img-sty2 {
    top: 38%;
    left: 26%;
  }
}

@media screen and (max-device-width: 1440px) {
  .question-image img {
    width: auto;
    height: 255px;
    object-fit: cover;
  }
}

@media screen and (min-device-width: 1440px) {
  .col-md-7.ftboxlinks-blue,
  .ftboxlinks-red a {
    font: normal normal bold 14px/28px Helvetica;
  }
}

@media screen and (min-device-width: 1270px) and (max-device-width: 1678px) {
  .left-img-sty {
    top: 8%;
    left: 19%;
  }

  .right-img-sty {
    top: 4%;
    right: 26%;
  }

  .right-img-sty-2 {
    top: 18%;
    right: 26%;
  }

  span.mid-img-sty2 {
    left: 35%;
    bottom: 96%;
  }

  span.mid-img-sty {
    bottom: 96%;
    right: 35%;
  }

  .right-img-sty3 {
    left: 23%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1528px) {
  .col-lg-6.region.region-content.welcome {
    padding-top: 0 !important;
  }
}

/* responsive css for laptop */
@media only screen and (max-width: 1389px) {
  .lastBar {
    margin: 0px 0px !important;
    height: 50%;
    width: 100%;
  }

  img.checkboxImages {
    width: 80%;
  }

  .left-content .col-lg-10.col-sm-10.col-xs-10 {
    max-width: 75%;
    width: 75% !important;
  }
}

@media only screen and (max-width: 1440px) {
  .ftboxlinks-blue a {
    font-size: 12px !important;
  }

  .ftboxlinks-red a {
    font-size: 12px !important;
  }

  .left-content .col-lg-10.col-sm-10.col-xs-10 {
    max-width: 75%;
    width: 75% !important;
  }
}

@media only screen and (min-width: 1162px) {
  .left-content .col-lg-10.col-sm-10.col-xs-10 {
    max-width: 75%;
    width: 75% !important;
  }
}

@media only screen and (min-width: 1154px) {
  .left-content .col-lg-10.col-sm-10.col-xs-10 {
    max-width: 75%;
    width: 75% !important;
  }
}

@media only screen and (max-width: 991px) {
  .col-lg-6.region.region-content1 {
    padding: 8% !important;
  }
}

span.breakWord {
  word-break: break-all;
}

a.breakWord {
  word-break: break-all;
}

@media only screen and (max-width: 1656px) {
  .col-lg-6.region.region-content1 {
    margin: 3% 0px 12% 0px !important;
  }
}

/* responsive css for laptop: 1216 */
@media only screen and (max-width: 1216px) {
  /* button.user-reponse-submit.save-draft-btn.btn.btn-primary {
        right: 40px;
        top: 85%
    } */

  .ftboxlinks-blue a {
    font-size: 11px !important;
  }

  .ftboxlinks-red a {
    font-size: 11px !important;
  }

  table.thinking_table {
    margin-bottom: 20px;
    width: 100%;
  }

  .content-topheads-text .row .col-lg-10 {
    max-width: 75% !important;
  }

  .content-topheads-text .row .col-lg-2 {
    max-width: 25% !important;
  }

  audio.react-audio-player {
    width: -webkit-fill-available;
  }

  .img-how-to-sty.centre-align img {
    width: -webkit-fill-available;
  }
}

@media only screen and (max-width: 1216px) and (min-width: 1042px) {
  .ftboxlinks-blue a {
    font-size: 9px !important;
  }

  .ftboxlinks-red a {
    font-size: 9px !important;
  }
}

@media only screen and (max-width: 992px) {
  .questions-table tbody tr {
    display: grid;
  }

  table.table.questions-table.predictions td.options {
    width: 100%;
  }
}

/* responsive css for mobile */
@media only screen and (max-width: 768px) {
  .passStren {
    position: absolute;
    right: 0;
    font-size: 12px;
  }

  small.text-danger.password-match-instr {
    top: 100%;
    margin-top: 5px;
    position: absolute;
  }

  .pagehead-texttitle {
    /* margin-top: 10px; */
  }

  .content-widthsidebar {
    margin-left: 0px !important;
    padding: 20px;
    max-width: 100%;
  }

  .dashboard-footer {
    left: 0;
    width: 100% !important;
  }

  .dashboard-footer .row.footer {
    /* padding: 10px 0px; */
    padding-bottom: 10px;
  }

  .ftboxlinks-red {
    width: 50%;
  }

  .ftboxlinks-blue {
    width: 50%;
  }

  .content-with-sidebar-row .content-wrapper {
    width: 100%;
    max-width: 100%;
  }

  .questions-table tbody tr {
    display: grid;
  }

  .questions-table tbody tr td {
    width: 100%;
  }

  .questions-table tbody tr .avoidence {
    width: unset;
  }

  .text-right {
    text-align: left !important;
  }

  .content-with-sidebar-row .content-wrapper {
    padding-bottom: 280px;
  }

  .img-how-to-sty.m-3 img {
    object-fit: contain !important;
    max-width: 480px;
    width: -webkit-fill-available;
  }

  .row.flx-col .col-sm-6 {
    width: 50%;
  }

  .row.flx-col .col-sm-6 img {
    width: -webkit-fill-available;
  }

  .left-content .col-lg-10.col-sm-10.col-xs-10 {
    max-width: 100% !important;
    width: 100% !important;
  }

  .question-image img {
    width: -webkit-fill-available;
  }

  .content-topheads-text .row .col-lg-2 {
    max-width: 100% !important;
    width: 100% !important;
  }

  .col-md-10.content-widthsidebar.content-wrapper {
    padding-bottom: 150px;
  }

  .ipForms {
    flex-flow: column-reverse;
  }

  .question-image img {
    height: 300px;
    width: auto;
  }

  table.thinking_table {
    width: 100%;
  }

  .row.content-with-sidebar-row.m-0
    .col-md-10.content-wrapper.content-div
    .content {
    margin-bottom: 28px;
  }

  table.thinking_table {
    margin-bottom: 20px;
  }

  .img-how-to-sty.centre-align img {
    width: -webkit-fill-available;
  }
}

@media screen and (min-device-width: 757px) and (max-device-width: 820px) {
  .content-widthsidebar {
    margin-left: 0px !important;
    min-width: 100%;
    transition: margin-left 0.3s ease-in-out;
  }

  body.sidebar-mini.sidebar-open
    aside.main-sidebar.sidebar-dark-primary.elevation-4 {
    display: block !important;
    top: 56px;
  }

  .position-static {
    width: 0px;
    max-width: 0px;
  }

  .content-with-sidebar-row .content-wrapper {
    max-width: 100% !important;
  }

  /* aside.main-sidebar.sidebar-dark-primary.elevation-4 {
    display: none;
  } */

  .dashboard-footer {
    width: 100% !important;
    left: 0px !important;
  }

  .ftboxlinks-blue a {
    font-size: 8px !important;
  }

  table.thinking_table {
    margin-bottom: 20px;
  }

  .ftboxlinks-blue a,
  .ftboxlinks-red a {
    font-size: 8px !important;
  }
}

@media (max-width: 768px) {
  .footerSuperHero {
    height: unset !important;
  }
}

@media (max-width: 1400px) {
  .dashboard-footer {
    padding: 0px 14px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1394px) {
  .img-how-to-sty.m-3 img {
    height: auto;
    width: 600px;
  }

  .img-how-to-sty img {
    width: -webkit-fill-available !important;
  }

  .img-how-to-sty img {
    /* width: -webkit-fill-available !important; */
    height: auto;
    object-fit: cover;
  }

  .img-how-to-sty.centre-align img {
    width: auto;
    margin-bottom: 15px;
  }
}

@media screen and (max-device-width: 951px) {
  .img-how-to-sty img {
    width: -webkit-fill-available;
    margin-bottom: 10px;
  }

  .imghowtosty img {
    width: -webkit-fill-available;
  }

  .img-how-to-sty.centre-align img {
    width: -webkit-fill-available;
  }
}

/* testing .css */
.content-wth-bg,
.mainContent {
  height: 100vh;
}

.content-wth-bg {
  overflow: scroll;
}

.first-content-ul,
.second-content-ul {
  width: fit-content;
}

div#lowerRow {
  position: relative;
}

div#firstCol {
  position: absolute;
  left: 11.3%;
  top: 42px;
  background-color: #ebe4f1;
  border: 1px dashed #673092;
  width: 173px;
  min-height: 200px;
}

#firstCol ul,
#secondCol ul,
#thirdCol ul {
  text-align: left;
}

div#secondCol {
  top: 24px;
  left: 37.7%;
  background-color: #e3f4fb;
  border: 1px dashed #29aae2;
  width: 172px;
  min-height: 200px;
  position: absolute;
}

#thirdCol {
  background-color: #ebe4f1;
  border: 1px dashed #673092;
  width: 172px;
  min-height: 200px;
  position: absolute;
  left: 63.99%;
  top: 42px;
}

#lowerRow .loader-icon {
  height: min-content;
  width: min-content;
}

div#lowerRow {
  width: 835px;
}

span.item-menu-list.active a.link-enable {
  color: #000000 !important;
}

span.item-menu-list.active a.link-disable {
  color: #323232;
}

[class*="sidebar-dark-"] .sidebar a {
  color: #ebebeb;
}

span.headText .link-enable {
  color: #fff !important;
  font-size: 18px;
  font-weight: 700;
}

span.pageList {
  font-size: 12px !important;
}

p.styTxt a.atlink {
  border-bottom: 1px dotted;
}

p.styTxt a.atlink:hover {
  border-bottom-style: solid;
}

.progressBar .progress-wrap {
  display: flex;
}

.progressBar .progress {
  width: 100%;
}

.progress-wrap {
  align-items: center;
}

.progress-wrap p {
  margin-top: 17px;
  margin-right: 13px;
  width: 9%;
}

.progressBar .progress {
  width: 5%;
  margin-right: 20px;
}

.progress-bar.before {
  background: #ff0000;
}

.progress-bar.before {
  background: #ff0000;
}

.progress-bar.after {
  background: #00ff00;
}

/* admin panle css */
.adminPanel table tr {
  padding: 0px 10px;
  border-bottom: 1px solid #00000020;
}

.adminPanel table tr th,
td {
  padding: 8px 0px;
  font-family: var(--font_comicRegular);
}

.adminPanel {
  height: 100vh;
  overflow: scroll;
  padding: 10px;
  background: #f0f0f0;
}

.row.topBar {
  background: #fff;
  height: 40px !important;
  align-items: center;
  margin: 0px 0px;
  padding: 10px;
  border-radius: 4px;
}

.row.topBar .col-lg-2,
.row.topBar .col-lg-1 {
  text-align: right;
}

.row.topBar .col-lg-1 button {
  background: var(--yellow) !important;
  border-radius: 4px !important;
  width: 81px;
  height: 31px !important;
}

.searchIp {
  border: 1px solid #dfdfdf !important;
  height: 40px;
  border-radius: 4px !important;
  width: 100%;
  padding-left: 10px;
}

.adminPanel table {
  width: -webkit-fill-available;
  background: #fff;
}

.checkBox {
  text-align: center;
}

.tableMain {
  border-radius: 4px;
  box-shadow: 3px 3px 10px 3px #ccc;
}

.pagination {
  align-items: center;
}

.pagination button {
  border: 1px solid;
  border-color: transparent !important;
}

.pagination span {
  color: var(--mahroon);
  font-size: 13px !important;
}

.pagination select {
  border: 1px solid #ccc;
  height: 31px;
  background: transparent;
}

.pagination input {
  border: 1px solid #ccc;
  height: 31px;
  margin: 2px;
}

/* .row.toprow {margin: 0px 35px;} */
.adminPanledash nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  margin: 0px 10px;
}

.fade.modelNew.modal.show .modal-dialog {
  top: 33%;
}

button.btn-close::before {
  content: "\f00d";
}

button.btn.btn-primary {
  background-color: var(--yellow);
  /* border: none !important; */
  color: var(--mahroon) !important;
}

button.btn-close {
  background: none;
  border: none;
}

@media screen and (max-device-width: 392px) {
  /* button.user-reponse-submit.save-draft-btn.btn.btn-primary {
        top: calc(100% - 200px) !important;
    } */
}

@media screen and (max-device-width: 500px) {
  /* button.user-reponse-submit.save-draft-btn.btn.btn-primary {
        right: 15px !important;
        top: 81% !important;
    } */
  img.weclomePng {
    /* background: red; */
    height: auto !important;
  }

  .dashboardContent.error-template .col-lg-3 {
    display: none;
  }

  .regbtn-block .btn.prevbtn {
    width: 150px;
  }

  button.btn.nextbtn.btn-block.btn-primary.btn-sm {
    width: 126px;
  }

  .row.topBar {
    display: flex;
    height: 76px !important;
  }

  .oterDiv {
    width: 1200px;
  }

  .row.topBar .col-lg-5 {
    display: none;
  }

  .row.topBar .col-lg-2,
  .row.topBar .col-lg-4,
  .row.topBar .col-lg-1 {
    width: 33%;
  }

  input.searchIp {
    float: left;
  }

  .dashboardContent.error-template img {
    width: 60%;
  }

  .dashboardContent.error-template .col-lg-6.region.region-content {
    margin: 25px;
    /* height: auto !important; */
  }

  .dashboardContent.error-template .col-lg-6.region.region-content.welcome {
    margin: 25px;
    /* height: auto !important; */
  }
}

/* end */

@media screen and (max-device-width: 1230px) {
  .row.topBar {
    display: flex;
    height: 76px !important;
  }

  .img-how-to-sty img {
    width: -webkit-fill-available !important;
  }

  .oterDiv {
    width: 1200px;
  }

  .row.topBar .col-lg-5 {
    display: none;
  }

  .row.topBar .col-lg-2,
  .row.topBar .col-lg-4,
  .row.topBar .col-lg-1 {
    width: 33%;
  }

  .row.topBar .col-lg-1 button {
    float: left;
  }
}

.link-disable-wrap,
.link-enable-wrap {
  white-space: break-spaces;
  font-size: 16px;
  font-weight: 400;
}

.imghowtosty {
  margin: 26px !important;
  text-align: center;
}

table.challenge_table tr td {
  padding: 10px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef87 !important;
}

/* @media screen and (max-device-width: 800px) {
  body.sidebar-mini.sidebar-open aside.main-sidebar.sidebar-dark-primary.elevation-4 {
    display: block !important;
    top: 57px;
  }
} */

@media (max-width: 768px) {
  body.sidebar-mini.sidebar-open
    aside.main-sidebar.sidebar-dark-primary.elevation-4 {
    display: block !important;
    top: 56px;
  }

  .content-widthsidebar {
    margin-left: 0px !important;
    padding: 16px;
    max-width: 100%;
  }
}

/* model page  */
.first-content-ul.pt-3,
.second-content-ul.pt-3 {
  margin-right: 22px;
  width: 401px;
  margin-top: 5px;
}

.second-content-ul.pt-3 ul.pt-3 li p,
.first-content-ul.pt-3 ul.pt-3 li p {
  text-align: left;
}

@-moz-document url-prefix() {
  .first-content-ul.pt-3 {
    position: absolute;
    right: 32%;
    /* width: 457px;
     */
    width: 436px;
  }

  .mainContent {
    position: relative;
  }

  .second-content-ul.pt-3 {
    position: absolute;
    left: 31.3%;
    width: 454px !important;
  }

  .second-content-ul.pt-3 {
    position: absolute;
    left: 31.3%;
    width: 454px !important;
    top: 65%;
  }

  .afterDiv {
    position: relative;
  }

  div#firstCol {
    position: absolute;
    left: 34.4%;
    top: 250px;
    background-color: #ebe4f1;
    border: 1px dashed #673092;
    width: 172px;
    min-height: 200px;
  }

  div#secondCol {
    position: absolute;
    top: 227px;
    left: 60.7%;
    background-color: #e3f4fb;
    border: 1px dashed #29aae2;
    width: 172px;
    min-height: 200px;
  }

  #thirdCol {
    background-color: #ebe4f1;
    border: 1px dashed #673092;
    width: 172px;
    min-height: 200px;
    position: absolute;
    left: 86.9%;
    top: 250px;
  }
}

.content-wrap.whatLearned {
  margin-bottom: 30px;
}

.row.ipForms .textbox {
  width: 100%;
}

@media (max-width: 992px) {
  .row.ipForms .textbox {
    width: 100%;
  }

  .visual-container-footer .visual-container-footer-lower-text {
    font-size: 30px !important;
  }

  .normal-text-black-complete {
    font-size: 30px !important;
  }

  .visual-container-footer .normal-text-black {
    font-size: 24px !important;
  }

  .main-visual-container .visual-container-superhero {
    width: 100% !important;
  }

  .outerMost-visual-container .main-visual-container {
    width: 100% !important;
  }

  .main-visual-container .visual-container-superhero {
    width: 100% !important;
  }
}

/* complete page chart */
.lastBar {
  /* width: 80%; */
  padding: 1%;
  align-items: center;
  /* height: 80%; */
}

/* .alignCenter {align-items: center;} */
.marginBotoom.mt-3.card.mb-4 .alignCenter.p-3 .lastBar {
  width: 100%;
  padding: 0px 7%;
}

/* .marginBotoom.mt-3.card.mb-4 {
  margin-bottom: 10rem !important;
} */

.marginBotoom.card.mb-4 {
  margin-bottom: 10rem !important;
}

.dashboardContent.error-template .row {
  background: var(--light-brown) !important;
  height: 100vh;
  overflow: scroll;
}

img.weclomePng {
  /* height: 300px; */
  object-fit: contain;
}

.content.welcomeContent {
  padding: 10px 19%;
  text-align: justify;
}

button.user-reponse-submit.save-draft-btn.btn.btn-primary {
  /* position: fixed !important; */
  /* right: 3%; */
  /* top: calc(100% - 240px); */
  /* z-index: 99999; */
  width: 83px;
  /* margin-right: 29px; */
}

.draftButton {
  /* width: 83px !important; */
  /* margin: 14px 1px 0px 9px !important; */
  /* padding: 4px; */
  background: #fff !important;
  color: #000;
  font-weight: bold;
  width: fit-content;
  margin-left: 0px;
  font-size: 20px;
  border-radius: 10px;
  padding: 4px 10px;
  font-family: var(--font_comicRegular);
  padding: 20px 30px;
  border: 1px solid #fff !important;
}

.draftButton:focus {
  color: #000;
  border: #fff;
}

.draftButton:hover {
  border: 1px solid #ddd;
  color: #000;
}

.row.footer .ft-headstext {
  text-align: center;
}

/* .row.footer .audio-title,
.ft-headstext.needHelp {
    visibility: hidden;
} */

.dashboardContent.error-template img {
  object-fit: scale-down;
  width: 40%;
}

.content.welcomeContent1 {
  text-align: justify;
  font-family: var(--font_comicRegular);
  font-weight: 600;
}

.col-lg-6.region.region-content1 {
  text-align: left;
  padding-top: 5%;
  box-shadow: 5px 7px 20px 0px #cccc;
  margin: 3% 0px 9% 0px;
  background: #ffffff54;
  border-radius: 8px;
  padding: 1%;
  font-size: "30px";
}

#heading {
  font-size: 30px !important;
}

#para {
  color: var(--mahroon);
  font-size: 24px;
  font-weight: 600;
}

#StaticFooter p:hover {
  color: blue;
}

.fixedDiv {
  position: fixed !important;
  top: 92%;
  background: #f1f2f2;
  padding: 2%;
}

.next {
  display: flex;
  justify-content: flex-end;
  padding-right: 13px;
}

/* for audio clue hidden */

.audio-title {
  visibility: hidden;
}

.col-md-4.row.footer .ft .needHelp {
  margin-bottom: 8px;
  text-align: left !important;
}

.text {
  font-size: 16px !important;
}

/* part4 new image style */

.sean_edited_image {
  width: 250px !important;
  height: auto;
}

.equation_edited_image {
  width: 530px !important;
  height: "auto";
}

/*part5 new image style*/

.part5_shop2_image {
  width: 550px;
  height: auto;
}

/*part6 new image style */

.part6_bar_image {
  width: 550px;
  height: auto;
}

.part6_imagery2_image {
  width: 550px;
}

/* part 3 new image style */

.part3_intro_image {
  width: 660px;
  height: auto;
}

.part3_benmodel_image {
  width: 600px;
  height: auto;
}

.part3_j2_image {
  width: 250px;
  height: auto;
}

.part3_j3_image {
  width: 400px;
  height: auto;
}

.part3_susan1_image {
  width: 200px;
  height: auto;
}

.part2_susan3_image {
  /* width: 320px; */
  width: 350px;
  height: auto;
}

.part3_more_safety_images {
  height: 142px;
}

/* part1 new images style */

.part1_everyone_image {
  width: 330px;
  height: auto;
}

.part1_waiting_other_images {
  width: 280px;
  height: auto;
}

.part1_park_image {
  width: 750px;
  height: auto;
}

/* styling of the visual of the Complete module  */

.outerMost-visual-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1532px;
}

.outerMost-visual-container .main-visual-container {
  width: 40%;
  margin-bottom: 70px;
}

.outerMost-visual-container .fourth-module-visual-container {
  width: 50%;
}

.main-visual-container-1 {
  display: flex;
  width: 100%;
  justify-content: center;
}

.fourth-main-visual-container-1 {
  justify-content: center !important;
}

.main-visual-container-2 {
  display: flex;
  justify-content: center;
  /* cursor: pointer; */
  position: relative;
}

.main-visual-container-3 {
  display: flex;
  justify-content: center;
  /* cursor: pointer; */
  position: relative;
}

.main-visual-container-4 {
  display: flex;
  justify-content: center;
}

.visuals-container {
  position: relative;
  /* display: "flex" !important;
    justify-content: "center" !important;
    flex-direction: "column" !important;
    align-items: "center" !important; */

  display: flex;
  flex-direction: column;
  width: 100%;
}

.visuals-container .textOver-graphics {
  position: absolute;
  top: 40% !important;
  left: 0;
  right: 0;
  margin: auto;
}

.visuals-container .textOver-graphics .text1 {
  color: #6a3851;
  font-family: var(--font_banger);
  font-size: 45px !important;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 35px;
  text-align: center;
}

.visuals-container .textOver-graphics .text2 {
  color: #fecb00;
  font-family: var(--font_banger);
  font-size: 75px !important;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.visuals-container .visual-container-footer {
  margin-top: 40px;
  margin: 0 auto;
}

.visuals-container .visual-container-footer .text,
.visuals-container .visual-container-footer .boldText {
  color: var(--midium_black);
  font-family: var(--font_comicRegular);
  font-weight: 400;
  line-height: 24px;
}

.visuals-container .visual-container-footer .boldText {
  font-weight: 700;
}

.tophead-container {
  padding: 0px !important;
  display: flex;
  width: 100%;
  justify-content: center;
}

.background-image-gif {
  opacity: 0.5;
  width: 100%;
  height: 745px;
}

.part-complete-image {
  opacity: 0.5;
  width: 100%;
  height: 745px;
}

.visual-container-complete-program {
  position: relative;
  margin: auto !important;
  width: 1557px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textOver-graphics-complete-program {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1508px !important;
  position: absolute;
  top: 30px !important;
  /* left: 23px; */
  padding-left: 10px;
  padding-right: 10px;
}

.bangerTextStyle {
  color: #6a3851;
  font-family: var(--font_banger);
  font-size: 40px !important;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 35px;
  text-align: center;
  line-height: 42px !important;
}

.visual-container-footer .visual-container-footer-lower-text {
  margin-bottom: 35px !important;
  margin-top: 35px !important;
}

.visual-container-footer-lower-text {
  font-size: 34px !important;
  text-align: center !important;
  color: #323232 !important;
  font-family: var(--font_comicRegular) !important;
  font-weight: 600 !important;
  line-height: 35px !important;
  max-width: 85% !important;
  margin: 0 auto !important;
}

.visual-container-superhero {
  width: 90% !important;
}

.main-visual-container-2 .gif_player {
  display: flex;
  justify-content: center;
  width: 100% !important;
}

.main-visual-container-3 .gif_player {
  display: flex;
  justify-content: center;
  width: 100% !important;
}

@media (max-width: 1900px) {
  .main-visual-container-1 {
    justify-content: center;
  }

  .main-visual-container-2 {
    justify-content: center;
  }

  .main-visual-container-3 {
    justify-content: center;
    /* margin-top: 44px; */
  }
}

@media (max-width: 590px) {
  .main-visual-container-1 {
    justify-content: center;
  }
}

@media (max-width: 1450px) {
  .main-visual-container-1 {
    justify-content: left;
  }

  .main-visual-container-2 {
    justify-content: right;
  }
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .visual-container-superhero {
    width: 70% !important;
  }

  .part4-images {
    width: 80% !important;
  }
}

@media (max-width: 1192px) and (min-width: 992px) {
  .visual-container-superhero {
    width: 80% !important;
  }

  .part4-images {
    width: 80% !important;
  }
}

@media (max-width: 1383px) and (min-width: 1025px) {
  .background-image-gif,
  .textOver-graphics-complete-program {
    height: 900px;
  }
}

@media (max-width: 868px) and (min-width: 821px) {
  .visuals-container .textOver-graphics .text2 {
    font-size: 67px !important;
  }
}

@media (max-width: 800px) and (min-width: 590px) {
  .visuals-container .textOver-graphics .text2 {
    font-size: 60px !important;
  }
}

@media (max-width: 600px) {
  .visuals-container .textOver-graphics {
    top: 25% !important;
  }
}

@media (max-width: 1024px) and (min-width: 500px) {
  .background-image-gif,
  .textOver-graphics-complete-program {
    height: 1200px !important;
  }
}

@media (max-width: 499px) {
  .background-image-gif,
  .textOver-graphics-complete-program {
    height: 1350px !important;
  }

  .bangerTextStyle {
    font-size: 32px !important;
  }

  .lastBar > canvas {
    height: 300px !important;
  }
}

@media (max-width: 1300px) and (min-width: 1415) {
  .main-visual-container-2 .gif_player {
    justify-content: right !important;
  }
}

@media (max-width: 1300px) {
  .outerMost-visual-container .main-visual-container {
    width: 100%;
  }

  .outerMost-visual-container .fourth-module-visual-container-1 {
    width: 100% !important;
  }

  .main-visual-container-2 .gif_player {
    justify-content: center;
  }

  .main-visual-container-1 {
    justify-content: center;
    /* height: 750.13px; */
  }

  .visual-container-footer-lower-text {
    max-width: 100% !important;
  }

  .main-visual-container-2 {
    justify-content: center;
    /* margin-top: 44px; */
  }

  .visuals-container .textOver-graphics {
    top: 200px;
  }
}

@media (max-width: 589px) {
  .main-visual-container-1 .visuals-container > img {
    width: 100%;
    max-height: 300px;
    margin: auto;
  }

  .visuals-container {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
  }

  .main-visual-container-1 {
    justify-content: center;
    height: auto;
  }

  .main-visual-container-2 > img,
  .main-visual-container-3 > img {
    /* max-width: 300px !important; */
    width: 100%;

    /* max-height: 300px !important; */
  }

  .visuals-container .textOver-graphics .text1 {
    font-size: 32px !important;
  }

  .visuals-container .textOver-graphics .text2 {
    font-size: 42px !important;
  }

  .main-visual-container-2 {
    justify-content: center;
    /* margin-top: 44px; */
  }
}

@media (max-width: 500px) {
  .visuals-container .textOver-graphics {
    top: 125px;
    left: 57px;
  }

  .visuals-container .textOver-graphics .text1 {
    margin-bottom: 20px;
  }

  .visuals-container .textOver-graphics .text2 {
    font-size: 35px !important;
    line-height: 35px !important;
  }
}

@media (max-width: 460px) {
  .visuals-container .textOver-graphics {
    width: 100%;
    left: -11px;
  }
}

@media (max-width: 358px) {
  .background-image-gif,
  .textOver-graphics-complete-program {
    height: 1550px !important;
  }
}

/****** sideBar UserInfo*********/

.main-sidebar .sidebar .user-info-box {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  cursor: pointer;
  border-bottom: 1px solid #b2b2b2;
}

.main-sidebar .sidebar .user-info-box .user-name-box,
.main-sidebar .sidebar .user-info-box .user-points-box {
  display: flex;
  width: 208px;
  padding: 10px;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  gap: 10px;
  background-color: var(--mahroon);
  border-radius: 10px;
  align-items: center;
  margin-top: 15px;
}

.main-sidebar .sidebar .user-info-box .user-points-box .logo-style {
  width: 42px;
  height: 42px;
}

.main-sidebar .sidebar .user-info-box .user-points-box span {
  word-break: break-all;
  color: var(--yellow) !important;
  font-family: var(--font_banger);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.main-sidebar .sidebar .user-info-box .user-name-box {
  background-color: inherit;
  padding: 0px;
  margin-top: 0px;
}

.main-sidebar .sidebar .user-info-box .user-name-box .logo-style {
  width: 55px;
  height: 55px;
  border-radius: 45px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.main-sidebar .sidebar .user-info-box .user-name-box .user-name {
  color: #fff;
  font-family: var(--font_comicRegular);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

/************************bottom-button-container*****************/
.bottom-button-container {
  border-radius: 0px 0px 4px 4px;
  background: rgba(235, 235, 235, 0.54);
  padding: 25px 16px;
}

/***************************************form control********************/

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: var(--mahroon);
  outline: 0;
  box-shadow: inset 0 0 0 transparent;
}

/********************** qestion in part-4 behavioual causl thought*****/

.left-content .row .col-xl-6 {
  /* padding-left: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: end;
  /* flex-flow: inherit; */
}

@media (max-width: 1200px) {
  .left-content .row .col-xl-6 {
    padding: 0px 16px;
  }
}

/************************ example container wiht pink background *************************/
.example-container-reverse,
.example-container {
  background: var(--light-pink-background);
  border-radius: 10px;
  background: #f2ecfa;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 50px 0px;
  padding: 40px;
  display: flex;
  flex-wrap: nowrap;
}

.example-container-reverse .main-container,
.example-container .main-container {
  margin-top: 0px;
}

.example-container .image-box {
  margin-right: 137px;
  width: 552px !important;
}

.example-container-reverse .image-box {
  margin-left: 137px;
  width: 552px !important;
}

.example-container-reverse .main-container .sub-container,
.example-container .main-container .sub-container {
  /* width: 100%; */
  width: 552px;
}

.example-container-reverse .text-box,
.example-container .text-box {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.outerMost-visual-container .fourth-module-visual-container-1 {
  width: 33.33%;
  margin-bottom: 70px;
}

.gif-play-button {
  position: absolute !important;
  top: 40% !important;
  cursor: pointer !important;
  width: 30% !important;
}

.gif-play-button1 {
  position: absolute !important;
  top: 40% !important;
  cursor: pointer !important;
  width: 30% !important;
}

.fourth-module-visual-container-1 .gif_player {
  width: 100% !important;
  display: flex;
  justify-content: center;
}

@media (max-width: 1600px) {
  .example-container .image-box {
    margin-right: 50px;
  }

  .example-container-reverse .image-box {
    margin-left: 50px;
  }
}

@media (max-width: 1450px) and (min-width: 1415px) {
  .gif-play-button1 {
    left: 42% !important;
  }
}

@media (max-width: 1414px) and (min-width: 1301px) {
  .gif-play-button1 {
    left: 50% !important;
  }
}

@media (max-width: 1400px) and (min-width: 1250px) {
  .outerMost-visual-container .fourth-module-visual-container-1 {
    width: 40% !important;
  }
}

@media (max-width: 1200px) {
  .example-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .example-container-reverse {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .example-container .image-box {
    margin-right: 0px;
    width: unset !important;
  }

  .example-container-reverse .image-box {
    margin-left: 0px;
    width: unset !important;
  }

  .example-container-reverse .main-container .sub-container,
  .example-container .main-container .sub-container {
    width: unset;
    /* width: 552px; */
  }

  .example-container-reverse .text-box,
  .example-container .text-box {
    margin-top: 50px;
    align-items: center;
  }

  .example-container-reverse .text-box p:first-child,
  .example-container .text-box p:first-child {
    text-align: center;
  }
}

@media (max-width: 992px) {
  .example-container-reverse,
  .example-container {
    padding: 20px;
  }
}

/**********Predictions part 4***********/
/* .predictions {
  background: url("../assets/GIf//background1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  object-fit: cover;
} */

.part4-tenthPage .question-container {
  display: flex;
  flex-grow: 1;
  /* justify-content: center; */
  /* height: -webkit-fill-available; */
  /* text-align: center; */
}

.audio-radio
/************************ progress bar ********************************/

.radio-options .input-group-text {
  display: unset;
  align-items: unset;
  padding: 0px;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: unset !important;
  border: unset !important;
  border-radius: unset !important;
  margin-bottom: 20px;
}

/************************************page situation style********************/
.padding-0 {
  padding: 0px;
}

.situation-box {
  background: var(--light-pink-background);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
}

.social-anxiety-model div {
  width: 70%;
}

.social-anxiety-model div img {
  box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 10%);
  border-radius: 10px;
  border: 1px solid #dfdfdf;
}

.content-topheads-text.tophead-container {
  border-bottom: 2px solid #3f0866 !important;
  padding-bottom: 40px !important;
}

.freebook-barchat {
  border-bottom: 2px solid #3f0866 !important;
  padding-bottom: 40px !important;
  margin-top: 50px !important;
}

.main-container-header {
  border-bottom: 2px solid #3f0866 !important;
  padding-bottom: 40px !important;
}

.heading-final {
  margin-bottom: 20px !important;
}

@media (max-width: 1300px) {
  .social-anxiety-model div {
    width: 100%;
  }
}

/***** Page heading/title stye ********/

.main-title-style,
.main-title-style-yellow,
.page-title-style,
.main-title-style-grey,
.component-heading-style {
  font-family: var(--font_banger) !important;
  font-size: 42px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 40px !important;
}

.main-title-style {
  color: var(--mahroon);
  margin: 6px 0px 0px 0px;
  -webkit-text-stroke: 0.5px black;
  font-size: 42px !important;
  font-family: var(--font_banger) !important;
}

.main-title-style-yellow {
  color: var(--yellow);
  -webkit-text-stroke: 0.5px black;
}

/* .main-title-style-grey {
    color: var(--title-grey);
    -webkit-text-stroke: 1px black;
} */

.page-title-style {
  color: var(--mahroon) !important;
  font-size: 38px !important;
  line-height: 40px !important;
  letter-spacing: 1px;
  /* margin-bottom: 30px; */
}

.component-heading-style {
  color: var(--mahroon);
  font-size: 35px !important;
  margin-bottom: 20px;
}

.component-sub-heading-style {
  color: #000;
  font-family: var(--font_comicRegular);
  font-size: 30px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 30px !important;
}

.draft-button-container {
  display: none !important;
}

/*media quesrees*/

@media (max-width: 992px) {
  .page-title-style {
    font-size: 35px !important;
    line-height: 30px !important;
  }

  .component-heading-style {
    font-size: 32px !important;
  }

  .component-sub-heading-style {
    font-size: 27px !important;
  }
}

@media (max-width: 567px) {
  .page-title-style {
    font-size: 30px !important;
    line-height: 30px !important;
  }

  .component-heading-style {
    font-size: 27px !important;
  }

  .component-sub-heading-style {
    font-size: 25px !important;
  }
}

@media (max-width: 540px) {
  .draftButton {
    display: none;
  }

  .draft-button-container {
    display: block !important;
    width: 100%;
    margin-bottom: 20px;
  }

  .draft-button-container .sm-draft-button {
    display: block !important;
    margin-left: auto;
    border: none;
    background: #fff !important;
    color: #000;
    font-weight: bold;
    width: fit-content;
    margin-left: 0px;
    font-size: 20px;
    border-radius: 10px;
    padding: 4px 10px;
    font-family: var(--font_comicRegular);
    padding: 20px 30px;
    border: 1px solid #fff !important;
  }
}

@media (max-width: 450px) {
  .draft-button-container .sm-draft-button,
  button.user-reponse-submit.btn.btn-primary.link-sty.btn.btn-primary {
    font-size: 14px;
    padding: 10px;
  }

  .draft-button-container .sm-draft-button {
    width: 100%;
  }
}

.normal-text {
  color: #323232;
  font-size: 24px !important;
  line-height: 30px !important;
  font-family: var(--font_comicRegular);
  font-weight: 700;
}

.normal-text-black {
  color: #000;
  font-size: 24px !important;
  line-height: 30px !important;
  font-family: var(--font_comicRegular);
  font-weight: 700;
}

.normal-text-black-complete {
  color: #000;
  font-size: 34px;
  line-height: 30px !important;
  font-family: var(--font_comicRegular);
  font-weight: 700;
}

.small-text-black,
.small-text,
.listing-black,
.listing {
  color: #323232;
  font-family: var(--font_comicRegular);
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  word-break: break-word;
}

.small-text-black,
.listing-black {
  color: #000 !important;
  font-weight: 700;
}

.subtitle-style,
.subtitle-style-grey {
  font-family: var(--font_comicRegular);
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.subtitle-style {
  color: var(--black);
}

.subtitle-style-grey {
  color: var(--subtitle-grey);
}

.short-font-size {
  font-family: var(--font_comicRegular);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.print-footer-style {
  font-family: var(--font_comicRegular);
  font-size: 14px !important;
  color: var(--midium_black);
}

/****************for print**************/
@media print {
  @page {
    margin: 10mm !important;
  }

  body {
    margin-bottom: 0mm;
  }

  .divider {
    page-break-before: always;
  }
}

@media (max-width: 1024px) {
  .sidebar-mini.sidebar-open .content-widthsidebar {
    max-width: 100% !important;
    margin-left: 0 !important;
    min-width: 100% !important;
  }

  body.sidebar-mini.sidebar-open
    aside.main-sidebar.sidebar-dark-primary.elevation-4 {
    display: block !important;
    top: 56px !important;
  }

  .dashboard-footer {
    left: 0;
  }
}
