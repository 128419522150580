body,
html {
  font-family: "Bangers" !important;
  overflow: auto !important;
  background-color: #f0f0f0;
}

.login-container-wrap {
  min-height: 100vh !important;
}

.login-col-wrap {
  width: 100%;
}

.login-submit,
.login-sign-up {
  margin-top: 40px;
}

.login-submit button {
  font-size: 20px !important;
}

.d-flex.align-items-center.login-container-wrap > div {
  width: 100%;
}

.reg-btn-block-superhero {
  width: 1019px;
}

.regbtn-block {
  padding: 0px 1.25rem;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  /* padding: 10px !important; */
}

.progress-barblock .progress {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.labelSection {
  display: flex;
  justify-content: space-between;
}

.passStren {
  display: flex;
  align-items: center;
  font: normal normal normal 14px/21px Helvetica;
  letter-spacing: 0px;
  color: #7d7d7d;
}

.passStren .progress {
  width: 100px;
  margin-left: 10px;
}

.forminut-passeye,
.form-group.forminut-confirmPassShow {
  position: relative;
}

.passShow-eye,
.confirmPassShow-eye {
  position: absolute;
  right: 10px;
  top: 37px;
}

.regbtn-block .prevbtn,
.regbtn-block .prevbtn + button.nextbtn {
  max-width: 150px;
  margin: 0px !important;
}

.login-col-wrap > .col-md-6 .text-center > img {
  max-width: 90%;
  margin-bottom: 175px;
  /* margin-bottom: 70px; */
}

.login-container-wrap .loginImage-container .text-center > img {
  /* margin-bottom: 70px; */
  max-width: 90%;
  margin-bottom: 175px;
}

.cofirmlabel input {
  margin-right: 10px;
}

.confirmpass-label-ins {
  font-family: var(--font_comicRegular);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.password-match-instr {
  font: normal normal normal 14px/21px var(--font_comicRegular);
  letter-spacing: 0px;
  color: #7d7d7d;
}

/*--- login ---*/

.pagehead-texttitle {
  font: normal normal bold 28px/34px var(--font_banger);
  letter-spacing: 0px;
  color: var(--mahroon);
  font-size: 62px;
  font-weight: 400;
  margin-bottom: 50px;
  line-height: 60px;
}

.input-label-style {
  font-family: var(--font_comicRegular);
  font: normal normal normal 16px/21px var(--font_comicRegular);
  font-size: 16px !important;
}

.login-container-wrap {
  font: normal normal normal 17px/21px Helvetica;
  letter-spacing: 0px;
  color: #323232;
}

.login-container-wrap a {
  font: normal normal normal 16px/21px var(--font_comicRegular);
  letter-spacing: 0px;
  /* color: #0f4beb; */
  color: var(--purple);
  font-size: 16px !important;
}

.btn.blue-style-btns,
.regbtn-block .btn.nextbtn {
  /* background: #0F4BEB; */
  background: var(--yellow);
  border-radius: 10px;
  /* border-color: #0F4BEB; */
  font: normal normal 500 15px/28px var(--font_comicRegular);
  letter-spacing: 0px;
  color: var(--mahroon);
  font-size: 17px;
  border: none;
  font-weight: 700;
  border: 1px solid var(--yellow) !important;
}

.regbtn-block .btn.prevbtn:hover,
.btn.blue-style-btns:hover,
.regbtn-block .btn.nextbtn:hover {
  border: 1px solid var(--mahroon) !important;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0px 1px 3px 1px #888888;
  color: var(--mahroon);
  /* opacity: 0.8; */
}

.regbtn-block .btn.prevbtn {
  background: #f8f9fa;
  border: 1px solid #dddddd;
  border-radius: 10px;
  font: normal normal 500 15px/28px var(--font_comicRegular);
  letter-spacing: 0px;
  color: #444444;
  font-size: 17px;
  border: 1px solid #ffffff;
}

/* .regbtn-block .btn.prevbtn:hover {
  outline: 1px solid var(--mahroon) !important;
} */

/* p {
  font: normal normal normal 17px/21px Helvetica;
  letter-spacing: 0px;
  color: #000000;
} */

.progress-bar {
  border-radius: 5px;
}

.usersituation-body p {
  font: normal normal normal 17px/21px var(--font_comicRegular);
  letter-spacing: 0px;
  color: #7d7d7d;
  font-size: 16px !important;
  line-height: 20px !important;
}

.cofirmlabel label {
  font: normal normal normal 17px/21px var(--font_comicRegular);
  letter-spacing: 0px;
  color: #7d7d7d;
}

.form-group.login-sign-up.text-center.signuptext.align-item-top.d-flex.flex-row.justify-content-center {
  padding: 0px 27px !important;
  align-items: center;
}

.form-control {
  font-family: var(--font_comicRegular);
}

/* input:focus {
  border: 1px solid var(--mahroon) !important;
} */

.pagehead-textsubtitle {
  font-size: 16px;
  margin-bottom: 70px;
  line-height: 24px !important;
  color: var(--midium_black);
  max-width: 700px;
  margin: auto;
  padding-bottom: 70px;
}

.audio-cardContainer {
  border-radius: 20px;
  max-width: 470px !important;
  background-color: #ffffff;
  margin: 0px 10px;
  /* margin-right: 30px; */
  /* max-width: 470px !important; */
}

.mediaButton-container:hover,
.audio-cardContainer:hover {
  cursor: pointer;
}

.audio-cardContainer .clinicain-name {
  font-family: var(--font_banger);
  font-size: 35px !important;
  line-height: 21px !important;
  font-weight: 400 !important;
  color: var(--mahroon);
}

.audioContainer-footer {
  padding: 10px 25px;
  height: 75px;
  /* pointer-events: none; */
  align-items: center !important;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.clinicain-name {
  /* pointer-events: auto; */
  margin: 0px !important;
}

/* .audioContainer-footer:hover {
    z-index: -1;
    background-color: var(--purple);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}


.first {
    z-index: 1;
    position: relative;
    height: 31px;
    width: 31px;
    background: #3f0866;
    border-radius: 50%;
}

.second {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid white;
    top: 10px;
    left: 13px;
}

.playOuter {
    position: relative;
    height: 31px;
    width: 31px;
    background: #3f0866;
    border-radius: 50%;
}

.playInner1 {
    position: absolute;
    width: 3px;
    height: 13px;
    background: white;
    top: 9px;
    left: 11px;
}

.playInner2 {
    position: absolute;
    width: 3px;
    height: 13px;
    background: white;
    top: 9px;
    left: 16.5px;
}

/* .clinicain-name:hover+.first {
    background-color: #ffffff;
}

.clinicain-name:hover+.first>.second {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid #3F0866;
    top: 10px;
    left: 13px;
} */

.registrationCotainer {
  margin: auto;
  max-width: 1520px !important;
}

.maincard-container {
  margin-bottom: 50px;
  justify-content: space-between;
  padding: 0px 10px;
}

.userInfoContainer {
  max-width: 1140px;
  /* margin: 0 auto; */
}

.buttonContainer-medium {
  max-width: 1140px;
  /* margin: 0px auto; */
}

.reg-btn-block {
  width: 1520px;
  margin-bottom: 100px;
}

.audio-control {
  height: 40px;
  background-color: var(--mahroon);
}

.hideOverFlow {
  margin-top: 280px;
}

.clinician-selected {
  border: 3px solid #3f0866;
}

.maincard-container .main-audio-container {
  position: relative;
  width: 470px;
}

/* .main-audio-container {
    position: relative;
    width: 470px;
} */

.mediaButton-container {
  position: absolute;
  right: 34px;
  bottom: 19px;
}

/* .registrationCotainer .register-footer-btn {
    margin-bottom: 120px !important;
} */

.selectedPause,
.selectedPlay,
.normalPuase,
.normalPlay {
  height: 36px;
  width: 36px;
}

.padding-in-button {
  padding: 0px 13px;
}

.error-box-reponsive {
  padding-bottom: 20px;
  padding-left: 12px;
}

.mainCrousal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  /* border: 1px solid red; */
  overflow: hidden;
  /* min-height: 520px; */
  /* position: relative; */
  /* white-space: nowrap; */
}

.mainCrousal-container .crousal-container {
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 520px;
  justify-content: center;
  position: relative;
}

.mainCrousal-container .crousal-container .crousal-nav {
  position: absolute;
  left: 145px;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainCrousal-container .crousal-container .crousal-nav button {
  border: none;
  outline: none;
  background-color: transparent;
}

.mainCrousal-container .crousal-container .crousal-nav button svg {
  border-radius: 50%;
  background-color: transparent;
  transition: all 350ms ease;
}

.mainCrousal-container .crousal-container .crousal-nav button svg:hover {
  transform: scale(1.1);
}

.mainCrousal-container .crousal-container .crousal-nav button svg:active {
  opacity: 0.15;
}

.mainCrousal-container .crousal-container .crousal-item {
  z-index: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 988px;
  height: 520px;
  border-radius: 20px;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 1px 1px 14px 1px #c5c4c4;
  transition: all 0.3s ease-in-out;
}

.superHero-image-container {
  border-radius: 20px;
  margin-right: 30px;
  box-shadow: 1px 1px 8px 1px #c5c4c4;
}

.audio-imageContainer .imageStyle {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.superHero-image-container .imageStyle {
  width: 460px;
  height: 440px;
}

.superHero-image-container img {
  border-radius: 20px;
}

.superHero-detail-container {
  display: flex;
  flex-direction: column;
}

.superHero-detail-container .superHero-title {
  font-size: 35px;
  font-family: var(--font_banger);
  color: var(--mahroon);
  line-height: 21px;
  padding-top: 28px;
}

.superHero-detail-container .superHero-des {
  /* font-size: 22px; */
  font-size: 16px !important;
  font-family: var(--font_comicRegular);
  line-height: 35px;
  font-weight: 700;
  color: var(--midium_black);
  margin-top: 25px;
}

.superHero-detail-container .select-button {
  background: var(--yellow);
  border: 1px solid var(--yellow);
  border-radius: 10px;
  font: normal normal 500 15px/28px var(--font_comicRegular);
  letter-spacing: 0px;
  color: var(--mahroon);
  font-size: 17px;
  max-width: 150px;
  margin-right: auto;
  margin-top: auto;
  font-weight: 700;
  /* line-height: 21px; */
  line-height: 30px;
  /* padding: 20px 30px; */
}

.superHero-detail-container .select-button:hover {
  background: #ffffff;
  border: 1px solid var(--mahroon);
  border-radius: 10px;
  color: var(--mahroon);
}

.mainCrousal-container .indicator-container {
  margin-top: 35px;
  margin-bottom: 15px;
  display: flex;
}

.mainCrousal-container .indicator-container .indicator-item {
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: rgba(63, 8, 102, 0.3);
  margin-right: 15px;
}

.active-dot-item {
  width: 80px;
  height: 20px;
  background-color: var(--purple);
  transition: all 0.3s ease-in-out;
  border-radius: 33px;
  cursor: pointer;
  margin-right: 15px;
}

/* .mainCrousal-container .crousal-container .crousal-item-1 {} */
.mainCrousal-container .crousal-container .crousal-item-1,
.mainCrousal-container .crousal-container .crousal-item-2,
.mainCrousal-container .crousal-container .crousal-item-3,
.mainCrousal-container .crousal-container .crousal-item-4,
.mainCrousal-container .crousal-container .crousal-item-5 {
  height: 520px;
  opacity: 0.3;
  top: 0px;
  transform: translateX(120%);
}

.mainCrousal-container .crousal-container .crousal-item-2,
.mainCrousal-container .crousal-container .crousal-item-3,
.mainCrousal-container .crousal-container .crousal-item-4,
.mainCrousal-container .crousal-container .crousal-item-5 {
  /* display: none; */
  opacity: 0;
}

.superhero-Cinicain-wrap {
  margin-top: 119px;
  background: #f0f0f0 !important;
}

.superHero-selected-button {
  background-color: var(--purple) !important;
  color: #ffffff !important;
  border: 1px solid var(--purple) !important;
}

.erorbox-Superhero {
  width: 988px;
  margin-top: 20px;
}

.error-message-style {
  font-size: 14px;
}

.form-control-icon-space {
  padding-right: 29px !important;
}

.select-input-box input:focus {
  border: none !important;
}

.nextbtn-styleForWidth {
  width: 134px !important;
}

/* All media queries */

@media (max-width: 1499px) {
  .hideOverFlow {
    margin-top: 500px;
  }

  .padding-in-button {
    padding: 0px 44px;
  }

  .maincard-container {
    justify-content: center;
  }

  .error-box-reponsive {
    padding-left: 0px;
  }

  .reg-btn-block-superhero {
    width: 918px;
  }
}

@media (max-width: 1489px) {
  .hideOverFlow {
    margin-top: 1100px;
  }

  .maincard-container {
    flex-wrap: nowrap !important;
  }

  .maincard-container .main-audio-container {
    width: unset;
  }

  .audio-imageContainer .imageStyle {
    width: 350px;
    height: 375px !important;
  }

  /* .main-audio-container:nth-child(3) {
    margin-top: 20px !important;
  } */
  .padding-in-button {
    padding: 0px;
  }

  .reg-btn-block {
    width: 1094px;
  }

  .card-container {
    max-width: 1094px !important;
  }

  .mainCrousal-container .crousal-container .crousal-item {
    width: 913px;
  }

  .erorbox-Superhero {
    width: 913px !important;
  }

  .mainCrousal-container .crousal-container .crousal-nav {
    /* left: 260px;  */
    top: 55%;
  }
}

@media (max-width: 1115px) {
  .audio-imageContainer .imageStyle {
    width: 250px !important;
    height: 275px !important;
  }

  .audio-cardContainer .clinicain-name {
    font-size: 30px !important;
    font-weight: 400 !important;
  }

  .reg-btn-block {
    width: 794px !important;
  }

  .error-box-reponsive {
    width: 794px;
    margin: auto;
  }
}

@media (max-width: 1024px) {
  body.sidebar-mini.sidebar-open
    aside.main-sidebar.sidebar-dark-primary.elevation-4 {
    display: block !important;
    top: 56px !important;
  }
  .sidebar {
    height: calc(100% - (56px));
  }

  .sidebar-mini.sidebar-collapse .main-sidebar,
  .sidebar-mini.sidebar-collapse .main-sidebar::before {
    width: 0;
  }

  .sidebar-mini.sidebar-open .content-widthsidebar {
    max-width: calc(100% - 250px) !important;
    margin-left: 250px !important;
  }

  .sidebar-mini.sidebar-closed .content-widthsidebar {
    margin-left: 0 !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .sidebar-mini.sidebar-closed .dashboard-footer {
    left: 0 !important;
  }

  .sidebar-mini.sidebar-closed
    .dashboardContent
    .col-md-2.position-static.pl-0 {
    width: 0 !important;
    display: none !important;
  }

  .sidebar-mini.sidebar-closed .dashboardContent .row {
    min-width: 100% !important;
  }
}

@media (max-width: 999px) {
  .hideOverFlow {
    margin-top: 1350px;
  }

  /* .padding-in-button {
    padding: 0px 34px;
  } */
}

@media (max-width: 992px) {
  .pagehead-texttitle {
    font-size: 50px;
    line-height: 45px;
    padding-top: 0px !important;
  }
}

@media (min-width: 980px) and (max-width: 999px) {
  /* .padding-in-button {
    padding: 0px 30px;
  } */
}

@media (min-width: 960px) and (max-width: 979px) {
  /* .padding-in-button {
    padding: 0px 20px;
  } */
}

@media (max-width: 959px) {
  /* .main-audio-container:nth-child(2) {
    margin-top: 20px !important;
  } */

  /* .reg-btn-block {
    max-width: 450px;
  } */
  .reg-btn-block-superhero {
    width: 441px;
  }

  .padding-in-button {
    padding: 0px 0px;
  }

  /* .error-box-reponsive {
    max-width: 450px;
    margin: 0px auto;
    padding-left: 0px;
  } */

  /* .mainCrousal-container {
        min-height: 770px;
    } */

  button.btn.btn-block.btn-sm.select-button {
    margin-left: auto;
    margin-right: auto;
  }

  .mainCrousal-container .crousal-container .crousal-nav {
    top: unset;
    left: unset;
    /* bottom: 30px;
    width: 295px; */
    bottom: -55px;
    width: auto;
    justify-content: space-between;
  }

  .mainCrousal-container .crousal-container .crousal-nav button svg {
    /* width: 55px; */
    width: 35px;
  }

  .mainCrousal-container .crousal-container {
    min-height: 720px;
    width: 100%;
  }

  .mainCrousal-container .crousal-container .crousal-item {
    width: 440px;
    height: 707px;
    flex-direction: column;
  }

  .superHero-image-container {
    margin-right: 0px;
  }

  .superHero-image-container .imageStyle {
    width: 380px;
    height: 380px;
  }

  .superHero-detail-container {
    height: 100%;
  }

  .superHero-detail-container .superHero-title {
    font-size: 30px;
    margin-bottom: 0px !important;
  }

  .erorbox-Superhero {
    width: 440px !important;
  }

  .superHero-detail-container .superHero-des {
    font-size: 16px !important;
    font-family: var(--font_comicRegular);
    line-height: 24px !important;
    font-weight: 700;
    color: var(--midium_black);
    /* margin-top: 25px; */
    margin-top: 24px !important;
  }
}

@media (max-width: 820px) {
  .maincard-container {
    flex-wrap: wrap !important;
  }

  .main-audio-container:nth-child(3) {
    margin-top: 20px !important;
  }

  .reg-btn-block {
    width: 522px !important;
  }

  .error-box-reponsive {
    width: 522px;
    margin: auto;
  }
}

@media (max-width: 559px) {
  .main-audio-container:nth-child(2) {
    margin-top: 20px !important;
  }

  .audio-imageContainer .imageStyle {
    width: 300px !important;
    height: 325px !important;
  }

  .reg-btn-block {
    width: 306px !important;
  }

  .pagehead-textsubtitle {
    padding-bottom: 50px;
  }

  .error-box-reponsive {
    width: 306px;
    margin: auto;
  }

  .superhero-Cinicain-wrap {
    margin-top: 40px;
  }

  .pagehead-texttitle {
    margin-bottom: 30px;
  }

  .pagehead-textsubtitle {
    padding-bottom: 15px;
    line-height: 35px;
  }

  .mainCrousal-container .indicator-container {
    margin-top: 5px;
    margin-bottom: 25px;
  }

  .erorbox-Superhero {
    margin-top: 5px;
    margin-bottom: 20px;
    text-align: center;
  }
}

@media (max-width: 769px) {
  .hideOverFlow {
    margin-top: 1600px;
  }

  /* .registrationCotainer .register-footer-btn {
    margin-bottom: 120px !important;
  } */
}

@media (max-width: 768px) {
  .loginimagestyle {
    margin-bottom: 30px;
    /* margin-top: 50px; */
  }

  .login-col-wrap > .col-md-6 .text-center > img {
    margin-bottom: 0px;
  }

  .login-container-wrap .loginImage-container .text-center > img {
    margin-bottom: 15px;
  }

  .pagehead-texttitle {
    line-height: 45px;
  }
}

@media (max-width: 600px) {
  .pagehead-texttitle {
    font-size: 45px;
  }
}

@media (max-width: 470px) {
  .pagehead-texttitle {
    font-size: 35px;
  }
}

/* media queries for croudal if less then 500px */
@media (max-width: 500px) {
  .mainCrousal-container .crousal-container {
    min-height: 660px;
    width: 100%;
  }

  .mainCrousal-container .crousal-container .crousal-item {
    width: 300px;
    height: 640px;
    flex-direction: column;
  }

  .superHero-image-container {
    margin-right: 0px;
  }

  .superHero-image-container .imageStyle {
    width: 240px;
    height: 240px;
  }

  .superHero-detail-container .superHero-title {
    font-size: 25px;
  }

  .erorbox-Superhero {
    width: 300px !important;
  }

  .reg-btn-block-superhero {
    max-width: 300px;
  }

  .remove-btnPadding {
    padding: 0px !important;
  }

  .mainCrousal-container .crousal-container .crousal-nav {
    /* bottom: 38px;
    width: 280px; */
    bottom: -50px;
    width: auto;
    justify-content: space-between;
  }
}
